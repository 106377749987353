
import { useEffect, useState } from 'react';
import './App.css';
import AddForm from "./components/AddForm/AddForm"
import  {ColorProvider}  from './components/ColorContext/ColorContext';
import UniName from './components/UniName/UniName';
import BreadCrum from './components/BreadCrum/BreadCrum';
import NavBar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// import { Route, Routes } from 'react-router-dom';


function App() {
  const [selectedItem, setSelectedItem] = useState('');
  

  const handleMenuItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <ColorProvider>
    <div className="App">
      { (
        <>
          <UniName />
          <NavBar handleMenuItemClick={handleMenuItemClick} />
          <BreadCrum selectedItem={selectedItem} />
        </>
      )}
        
        <Routes>
        <Route path="/*" element={<AddForm />} />
          </Routes>
      </div>
    </ColorProvider>
  );
}

export default App;
