import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/context/AuthContext';
import { AxiosProvider } from './components/context/AxiosContext';
import { BrowserRouter } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<BrowserRouter>
  <AuthProvider>
             <AxiosProvider>
                 <App />
             </AxiosProvider>
         </AuthProvider>
 </BrowserRouter>, document.getElementById('root'));