import React, { useContext } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import './breadcrum.css'
import { ColorContext } from '../ColorContext/ColorContext';
const BreadCrum = ({ selectedItem }) => {
  const { color } = useContext(ColorContext);
  return (
    <div className="breadcrumb-search row col-12">
  
      <div className="col-lg-6 col-sm-12" style={{ backgroundColor: color }}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item" aria-current="page" style={{ paddingTop: '15px' }}>
            {selectedItem}
            </li>
          </ol>
        </nav>
      </div>
      <div className="col-lg-6 col-sm-12 row">
        <div className="col-lg-8 col-sm-12">
          <form className="form-inline" style={{display:'flex' }}>
            <div className="form-group col-lg-4 col-sm-6">
              <input
                type="text"
                readOnly
                className="form-control-plaintext Search-Criteria1"
                id="staticEmail2"
                value="Search Criteria"
              />
            </div>
            <div className="form-group mx-sm-0 col-sm-6 Search-Criteria">
              <select className="form-control form-select col-12" style={{ width: '-webkit-fill-available', marginTop: '10px' }}>
                <option>Select</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div>
          </form>
        </div>
        <div className="col-lg-4 col-sm-6" style={{ marginTop: '10px' }}>
          <div>
            <div className="input-group rounded" >
              <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search Value" aria-describedby="search-addon" />
              <span className="input-group-text border-0" id="search-addon" style={{backgroundColor:'white'}}>
               
                <SearchIcon className='searchicon'/>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default BreadCrum