import React, { useState, useContext, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputMask from 'react-input-mask';
import 'bootstrap/dist/css/bootstrap.min.css';
import './addform.css'
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { AuthContext } from '../context/AuthContext';
import { AxiosContext } from '../context/AxiosContext';
import { Formik, Form, Field, ErrorMessage } from 'formik';
const AddForm = () => {
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const initialValues = {
    FirstName: '',
    LastName: '',
    MiddleName: '',
    CNIC: '',
    Email: '',
    MobileNO: '',
    DatePicker: '',
    PlaceofBirth: '',
    GenderId: '',
    NationalityId: '',
    DomicileId: '',
    BloodGroupId: '',
    MaretalStatusId: '',
    SpecialPerson: false,
    FatherDetail: {
      Name: '',
      FatherCNIC: '',
      FatherMobile: '',
      FatherOccupation: '',
      FatherIsAlive: true,
      FatherIsGuardian: false,
    },
    GuardianDetail: {
      GuardianRelationId: '',
      GuardianName: '',
      GuardianCNIC: '',
      GuardianMobile: '',
    },
    PresentAddress: {
      CountryId: '',
      ProviceId: '',
      DistrictId: '',
      TehsilId: '',
      CityId: '',
      PostalCode: '',
      HouseNumber: '',
      Street: '',
      Sector: '',
      MobileNumber: '',
      HomeNumber: '',
      Email: '',
      IsPermanentAddressSame: true
    },
    // Add more form fields here
  };

  const handleSubmit1 = async (values, { setSubmitting, resetForm, setFieldValue }) => {
    const dateOfBirth = selectedDate ? formatDate(selectedDate) : '';
    const data = {
      UserId: 1,
      PersonalDetail: {
        FirstName: values.FirstName || "",
        LastName: values.LastName || "",
        MiddleName: values.MiddleName || null,
        CNIC: cnic || '',
        Email: emails[0].value || '',
        Mobile: mob || '',
        DateOfBirth: dateOfBirth || '',
        PlaceOfBirth: values.PlaceofBirth || null,
        GenderId: values.GenderId || null,
        NationalityId: values.NationalityId || null,
        DomicileId: values.DomicileId || null,
        BloodGroupId: values.BloodGroupId || null,
        MaretalStatusId: values.MaretalStatusId || null,
        IsHafizQuran: false,
        SpecialPerson: values.SpecialPerson || false,
      },
      FatherDetail: {
        Name: values.Name || '',
        CNIC: cnic1 || '',
        Mobile: mob1 || '',
        Occupation: values.FatherOccupation || null,
        IsAlive: true,
        IsGuardian: values.FatherIsGuardian || false,
      },
      GuardianDetail: {
        GuardianRelationId: values.GuardianRelationId || null,
        Name: values.GuardianName || '',
        CNIC: cnic2 || '',
        Mobile: mob2 || '',
      },
      PresentAddress: {
        CountryId: values.CountryId || null,
        ProviceId: 1,
        DistrictId: 1,
        TehsilId: 1,
        CityId: 1,
        PostalCode: "45000",
        HouseNumber: "1",
        Street: "1",
        Sector: "G",
        MobileNumber: "03425564565",
        HomeNumber: "254645665",
        Email: "life347@hotmail.com",
        IsPermanentAddressSame: true
      },
    };
    console.log('data:', data);
    try {
      const response = await publicAxios.post('/Application/SaveDraft', data);
      console.log('Response data:', response.data);
      resetForm(); // Reset the form after successful submission
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }

    console.log('Form data:', values);
  };
  const authContext = useContext(AuthContext);
  const { authAxios } = useContext(AxiosContext);
  const { publicAxios } = useContext(AxiosContext);

  const [showDiv1, setShowDiv1] = useState(false);
  const [showDiv2, setShowDiv2] = useState(false);
  const [showDiv3, setShowDiv3] = useState(false);
  const [divs1, setDivs1] = useState([0]);
  const [divs, setDivs] = useState([0]);
  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');
  const [date3, setDate3] = useState('');
  const [date4, setDate4] = useState('');
  const [date5, setDate5] = useState('');
  const [isSpecialPerson, setIsSpecialPerson] = useState(false);
  const [isCurrentlyEmployed, setIsCurrentlyEmployed] = useState(false);
  const [cnic, setCnic] = useState('');
  const [cnic1, setCnic1] = useState('');
  const [cnic2, setCnic2] = useState('');
  const [mob, setmob] = useState('');
  const [mob1, setmob1] = useState('');
  const [mob2, setmob2] = useState('');
  const [mob3, setmob3] = useState('');
  const [mob4, setmob4] = useState('');
  const [cnicError, setCnicError] = useState(false);
  const [cnicError1, setCnicError1] = useState(false);
  const [cnicError2, setCnicError2] = useState(false);
  const [mobError, setmobError] = useState(false);
  const [mobError1, setmobError1] = useState(false);
  const [mobError2, setmobError2] = useState(false);
  const [mobError3, setmobError3] = useState(false);
  const [mobError4, setmobError4] = useState(false);

  const addDiv1 = () => {
    const newDivs1 = [...divs1];
    newDivs1.push(divs1.length);
    setDivs1(newDivs1);
  };
  const addDiv = () => {
    const newDivs = [...divs];
    newDivs.push(divs.length);
    setDivs(newDivs);
  };
  const toggleDiv1 = () => {
    setShowDiv1(!showDiv1);
  };


  const toggleDiv2 = () => {
    setShowDiv2(!showDiv2);
  };


  const toggleDiv3 = () => {
    setShowDiv3(!showDiv3);
  };


  const toggleSpecialPerson = () => {
    setIsSpecialPerson(!isSpecialPerson);
  };

  const toggleCurrentlyEmployed = () => {
    setIsCurrentlyEmployed(!isCurrentlyEmployed);
  };
  const initialEmails = [
    { id: 1, value: "", isValid: true },
    { id: 2, value: "", isValid: true },
    { id: 3, value: "", isValid: true },
    { id: 4, value: "", isValid: true },
    { id: 5, value: "", isValid: true },
    { id: 6, value: "", isValid: true },
  ];

  const [emails, setEmails] = useState(initialEmails);

  const handleChange = (e, id) => {
    const updatedEmails = emails.map((email) => {
      if (email.id === id) {
        return { ...email, value: e.target.value };
      }
      return email;
    });
    setEmails(updatedEmails);
  };

  const handleBlur = (id) => {
    const updatedEmails = emails.map((email) => {
      if (email.id === id) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return { ...email, isValid: emailRegex.test(email.value) };
      }
      return email;
    });
    setEmails(updatedEmails);
  };
  const [priority1SelectedValue, setPriority1SelectedValue] = useState("");
  const [priority2SelectedValue, setPriority2SelectedValue] = useState("");
  const [priority3SelectedValue, setPriority3SelectedValue] = useState("");
  const [bloodGroups, setBloodGroups] = useState([]);
  const [genders, setGenders] = useState([]);
  const [nationalitys, setNationality] = useState([]);
  const [Domiciles, setDomiciles] = useState([]);
  const [MaretalStatuss, setMaretalStatuss] = useState([])
  const [districts, setDistricts] = useState([]);
  const [allDistricts, setAllDistricts] = useState([]);
  const [tehsils, setTehsil] = useState([]);
  const [alltehsils, setAllTehsil] = useState([]);
  const [citys, setCity] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [degreeLevels, setDegreeLevel] = useState([])
  const [testTypes, setTestType] = useState([])
  const [programs, setPrograms] = useState([])
  const [allState, setAllState] = useState([]);
  const [provances, setProvances] = useState([])
  const [Countrys, setCountrys] = useState([])

  const [GuardianRelationships, setGuardianRelationship] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await publicAxios.get('/Application/PageData');
        const bloodGroupData = response.data.data.BloodGroup;
        const genderData = response.data.data.Gender;
        const nationalityData = response.data.data.Country
        const DomicileData = response.data.data.Domicile
        const MaretalStatusData = response.data.data.MaretalStatus
        const districtData = response.data.data.District
        const tehsilData = response.data.data.Tehsil
        const cityData = response.data.data.City
        const degreeLevel = response.data.data.DegreeLevel
        const testTypeData = response.data.data.TestType
        const programData = response.data.data.Programs
        const provanceData = response.data.data.Province
        const GuardianRelationshipData = response.data.data.GuardianRelationship
        const CountrysData = response.data.data.Country
        setBloodGroups(bloodGroupData);
        setGenders(genderData);
        setNationality(nationalityData);
        setDomiciles(DomicileData);
        setMaretalStatuss(MaretalStatusData);
        setDistricts(districtData);
        setAllDistricts(districtData);
        setTehsil(tehsilData);
        setAllTehsil(tehsilData)
        setCity(cityData)
        setAllCity(cityData)
        setDegreeLevel(degreeLevel)
        setTestType(testTypeData)
        setPrograms(programData)
        setProvances(provanceData)
        setAllState(provanceData)
        setGuardianRelationship(GuardianRelationshipData)
        setCountrys(CountrysData)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const handleCountryChange = (event) => {
    const selectedCountryId = Number(event.target.value);

    const filteredDistricts = allDistricts.filter((district) => {
      return district.ReferenceId === selectedCountryId;
    });
    console.log(selectedCountryId);
    setDistricts(filteredDistricts);


    const filteredCities = allCity.filter((city) => {
      return city.ReferenceId === selectedCountryId;
    });
    console.log(allCity);
    setCity(filteredCities);

    const filterProvance = allState.filter((provance) => {
      return provance.ReferenceId === selectedCountryId;
    })
    setProvances(filterProvance)

  };



  const handleDistrictChange = (event) => {
    const selectedDistrictId = Number(event.target.value);
    const filteredTehsil = alltehsils.filter((tehsil) => {
      return tehsil.ReferenceId === selectedDistrictId

    });
    console.log(selectedDistrictId)
    setTehsil(filteredTehsil)
  };


  const handleSubmit = () => {


    const userId = authContext.authState.user ? authContext.authState.user.Id : null;
    console.log('user Id:', userId)

    const validEmails = emails.filter((email) => email.isValid);
    console.log(validEmails);
    // Handle the form submission with the valid email values
  };





  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [selectedDate3, setSelectedDate3] = useState(null);
  const [selectedDate4, setSelectedDate4] = useState(null);
  const [selectedDate5, setSelectedDate5] = useState(null);


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDate1Change = (date) => {
    setSelectedDate1(date);
  };
  const handleDate2Change = (date) => {
    setSelectedDate2(date);
  };
  const handleDate3Change = (date) => {
    setSelectedDate3(date);
  };
  const handleDate4Change = (date) => {
    setSelectedDate4(date);
  };
  const handleDate5Change = (date) => {
    setSelectedDate5(date);
  };

  const currentDate = new Date();
  const handleCnicChange = (event) => {
    const inputValue = event.target.value;
    setCnic(inputValue);
    const filledNumbers = inputValue.replace(/-/g, '').length;
    const totalNumbers = inputValue.replace(/-/g, '').replace(/_/g, '').length;
    const isValid = filledNumbers === totalNumbers && totalNumbers === 13;
    setCnicError(!isValid);
  };
  const handleCnic1Change = (event) => {
    const inputValue = event.target.value;
    setCnic1(inputValue);
    const filledNumbers = inputValue.replace(/-/g, '').length;
    const totalNumbers = inputValue.replace(/-/g, '').replace(/_/g, '').length;
    const isValid = filledNumbers === totalNumbers && totalNumbers === 13;
    setCnicError1(!isValid);
  };
  const handleCnic2Change = (event) => {
    const inputValue = event.target.value;
    setCnic2(inputValue);
    const filledNumbers = inputValue.replace(/-/g, '').length;
    const totalNumbers = inputValue.replace(/-/g, '').replace(/_/g, '').length;
    const isValid = filledNumbers === totalNumbers && totalNumbers === 13;
    setCnicError2(!isValid);
  };
  const handlemobChange = (event) => {
    const inputValue = event.target.value;
    setmob(inputValue);
    const filledNumbers = inputValue.replace(/-/g, '').length;
    const totalNumbers = inputValue.replace(/-/g, '').replace(/_/g, '').length;
    const isValid = filledNumbers === totalNumbers && totalNumbers === 11;
    setmobError(!isValid);
  };
  const handlemob1Change = (event) => {
    const inputValue = event.target.value;
    setmob1(inputValue);
    const filledNumbers = inputValue.replace(/-/g, '').length;
    const totalNumbers = inputValue.replace(/-/g, '').replace(/_/g, '').length;
    const isValid = filledNumbers === totalNumbers && totalNumbers === 11;
    setmobError1(!isValid);
  };
  const handlemob2Change = (event) => {
    const inputValue = event.target.value;
    setmob2(inputValue);
    const filledNumbers = inputValue.replace(/-/g, '').length;
    const totalNumbers = inputValue.replace(/-/g, '').replace(/_/g, '').length;
    const isValid = filledNumbers === totalNumbers && totalNumbers === 11;
    setmobError2(!isValid);
  };
  const handlemob3Change = (event) => {
    const inputValue = event.target.value;
    setmob3(inputValue);
    const filledNumbers = inputValue.replace(/-/g, '').length;
    const totalNumbers = inputValue.replace(/-/g, '').replace(/_/g, '').length;
    const isValid = filledNumbers === totalNumbers && totalNumbers === 11;
    setmobError3(!isValid);
  };
  const handlemob4Change = (event) => {
    const inputValue = event.target.value;
    setmob4(inputValue);
    const filledNumbers = inputValue.replace(/-/g, '').length;
    const totalNumbers = inputValue.replace(/-/g, '').replace(/_/g, '').length;
    const isValid = filledNumbers === totalNumbers && totalNumbers === 11;
    setmobError4(!isValid);
  };

  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit1}>
        {({ values, setFieldValue }) => (
          <Form>
            <div
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>1. Personal Information</Typography>
            </div>
            <h5 style={{ textIndent: "29%" }}>Verify Yes/No</h5>
            <div
              class="d-flex justify-content-between flex"
            >
              <div className="column formsInput formfield">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="FirstName">First Name</label>
                      <Field type="text" name="FirstName" class="form-control" id="FirstName" aria-describedby="emailHelp" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="MiddleName">Middle Name</label>
                      <Field type="text" name="MiddleName" className="form-control" id="MiddleName" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="LastName">Last Name</label>
                      <Field type="text" name="LastName" className="form-control" id="LastName" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                    {/* <button class="btn btn-secondary btn-2">No</button> */}
                  </div>
                </div>
              </div>
              <div className="col-3 link-1 anchor-tag">
                <label htmlFor="exampleInputPassword1"></label>
                <a href='#'>Photo</a>
              </div>
            </div>

            <div
              class="d-flex justify-content-between flex"
            >
              <div className="column formsInput formfield">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="CNIC">CNIC</label>
                      <Field className="form-control"
                        as={InputMask}
                        name="CNIC"
                        id="CNIC"
                        mask="99999-9999999-9"
                        value={cnic}
                        onChange={handleCnicChange}
                        onBlur={handleCnicChange} />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 link-1 anchor-tag">
                <a href='#'>CNIC Front Side</a>
              </div>
            </div>
            <div className="column formsInput flex" style={{ width: "80%" }}>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label htmlFor="dateInput">Date of Birth</label>
                    <Field
                      as={DatePicker}
                      name="dateInput"
                      id="dateInput"
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd-MMM-yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={currentDate}
                      className="form-control"
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <div class=" col-4" style={{ margin: "11px" }}>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                    <label class="form-check-label" for="exampleCheckbox">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                    <label class="form-check-label" for="exampleCheckbox">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label htmlFor="GenderId">Gender</label>
                    <Field className="form-control" id="gender" name="GenderId" />
                  </div>
                </div>
                <div class=" col-4" style={{ margin: "11px" }}>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                    <label class="form-check-label" for="exampleCheckbox">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                    <label class="form-check-label" for="exampleCheckbox">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label htmlFor="NationalityId">Nationality</label>
                    <Field className="form-control" id="nationality" name='NationalityId' />
                  </div>
                </div>
                <div class=" col-4" style={{ margin: "11px" }}>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                    <label class="form-check-label" for="exampleCheckbox">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                    <label class="form-check-label" for="exampleCheckbox">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex"
            >
              <div className="column formsInput formfield">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="DomicileId">Domicile</label>
                      <Field className="form-control" id='DomicileId' name='Domicile' />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 link-1 anchor-tag">
                <label htmlFor="exampleInputPassword1"></label>
                <a href='#'>Domicile Document</a>
              </div>
            </div>

            <div className="row formsInput flex">
              <div className="col-3">
                <label htmlFor="exampleInputEmail1">Special Person</label>
              </div>
              <div className="col-3">
                <label>
                  <input
                    type="radio"
                    name="radio1"
                    value="true"
                    id="radio1-yes"
                    style={{ marginLeft: "30px" }}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="radio1"
                    style={{ marginLeft: "25px" }}
                    id="radio1-no"
                    defaultChecked
                  />
                  No
                </label>
              </div>
            </div>
            {(
              <div
                class="d-flex justify-content-between flex"
              >
                <div className="column formsInput formfield">
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label htmlFor="exampleInputEmail1">Issuing Authority</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class=" col-4" style={{ margin: "11px" }}>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                        <label class="form-check-label" for="exampleCheckbox">
                          Yes
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                        <label class="form-check-label" for="exampleCheckbox">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label htmlFor="exampleInputPassword1">Reference No</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                        />
                      </div>
                    </div>
                    <div class=" col-4" style={{ margin: "11px" }}>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                        <label class="form-check-label" for="exampleCheckbox">
                          Yes
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                        <label class="form-check-label" for="exampleCheckbox">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-3 link-1 anchor-tag">
                  <a href='#'>Reference Document</a>
                </div>
              </div>
            )}


            <div
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>2. Father/Guardian's Particulars</Typography>
            </div>
            <h5 style={{ textIndent: "29%" }}>Verify Yes/No</h5>
            <div className="column formsInput flex" style={{ width: "80%" }}>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label htmlFor="exampleInputEmail1">Father Name</label>
                    <Field type="text" className="form-control" id="FatherName" name='Name' aria-describedby="emailHelp" placeholder="Father Name" />
                  </div>
                </div>
                <div class=" col-4" style={{ margin: "11px" }}>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                    <label class="form-check-label" for="exampleCheckbox">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                    <label class="form-check-label" for="exampleCheckbox">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex"
            >
              <div className="column formsInput formfield">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="exampleInputPassword1">CNIC</label>
                      <Field className="form-control" mask="99999-9999999-9" name='FatherCNIC' placeholder="CNIC" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 link-1 anchor-tag">
                <a href='#'>Father's CNIC</a>
              </div>
            </div>
            <div className="row formsInput flex">
              <div className="col-3">
                <label htmlFor="exampleInputEmail1">Guardian</label>
              </div>
              <div className="col-3">
                <label>
                  <input
                    type="radio"
                    name="radio1"
                    value="true"
                    id="radio1-yes"
                    style={{ marginLeft: "30px" }}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="radio1"
                    style={{ marginLeft: "25px" }}
                    id="radio1-no"
                    defaultChecked
                  />
                  No
                </label>
              </div>
            </div>
            <div className="column formsInput flex" style={{ width: "80%" }}>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label htmlFor="exampleInputEmail1">Guardian's Name</label>
                    <Field
                      name='GuardianName'
                      type="text"
                      className="form-control"
                      id="GuardianName"
                      aria-describedby="emailHelp"
                      placeholder="Guardian's Name"
                    />
                  </div>
                </div>
                <div class=" col-4" style={{ margin: "11px" }}>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                    <label class="form-check-label" for="exampleCheckbox">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                    <label class="form-check-label" for="exampleCheckbox">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex"
            >
              <div className="column formsInput formfield">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="exampleInputPassword1">CNIC</label>
                      <Field name='GuardianCNIC' id='GuardianCNIC' className="form-control" mask="99999-9999999-9" placeholder="CNIC" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 link-1 anchor-tag">
                <a href='#'>Guardian's CNIC</a>
              </div>
            </div>
            <div
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>3. Addresses</Typography>
            </div>
            <p className="Permanent_Address">
              Present Address
            </p>
            <h5 style={{ textIndent: "29%" }}>Verify Yes/No</h5>
            <div
              class="d-flex justify-content-between flex"
            >
              <div className="column formsInput formfield">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="exampleInputEmail1">Country</label>
                      <Field id='CountryId' name='CountryId' className="form-control" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="exampleInputPassword1">Province/State</label>
                      <Field type="text" name="Province-state" className="form-control" id="Province-state" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="exampleInputPassword1">District</label>
                      <Field type="text" name="District" className="form-control" id="District" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                    {/* <button class="btn btn-secondary btn-2">No</button> */}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Tehsil</label>
                      <Field type="text" name="Tehsil" class="form-control" id="Tehsil" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">City</label>
                      <Field type="text" name="City" class="form-control" id="City" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Postal/ZIP Code</label>
                      <Field type="text" name="Postal-code" class="form-control" id="Postal-code" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">House no</label>
                      <Field type="text" name="House-no" class="form-control" id="House-no" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="FiexampleInputPassword1rstName">Street/Road</label>
                      <Field type="text" name="Street-road" class="form-control" id="Street-road" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Sector</label>
                      <Field type="text" name="Sector" class="form-control" id="Sector" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Residence Tel</label>
                      <Field type="text" name="Residence-tel" class="form-control" id="Residence-tel" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="FirstNexampleInputPassword1ame">Mobile no</label>
                      <Field type="text" name="Mobile-no" class="form-control" id="Mobile-no" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Email</label>
                      <Field type="text" name="Email" class="form-control" id="Email" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row formsInput" style={{ marginLeft: 0 }}>
                  <div className="col-3">
                    <label htmlFor="exampleInputEmail1">If Permanent Address Is Different</label>
                  </div>
                  <div className="col-3">
                    <label>
                      <input
                        type="radio"
                        name="radio1"
                        value="true"
                        id="radio1-yes"
                        style={{ marginLeft: "93px" }}
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="radio1"
                        style={{ marginLeft: "25px" }}
                        id="radio1-no"

                        defaultChecked
                      />
                      No
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="exampleInputEmail1">Country</label>
                      <Field id='CountryId2' name='CountryId2' className="form-control" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="exampleInputPassword1">Province/State</label>
                      <Field type="text" name="Province-state2" className="form-control" id="Province-state2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label htmlFor="exampleInputPassword1">District</label>
                      <Field type="text" name="District2" className="form-control" id="District2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                    {/* <button class="btn btn-secondary btn-2">No</button> */}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Tehsil</label>
                      <Field type="text" name="Tehsil2" class="form-control" id="Tehsil2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">City</label>
                      <Field type="text" name="City2" class="form-control" id="City2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Postal/ZIP Code</label>
                      <Field type="text" name="Postal-code2" class="form-control" id="Postal-code2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">House no</label>
                      <Field type="text" name="House-no2" class="form-control" id="House-no2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="FiexampleInputPassword1rstName">Street/Road</label>
                      <Field type="text" name="Street-road2" class="form-control" id="Street-road2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Sector</label>
                      <Field type="text" name="Sector2" class="form-control" id="Sector2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Residence Tel</label>
                      <Field type="text" name="Residence-tel2" class="form-control" id="Residence-te2l" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="FirstNexampleInputPassword1ame">Mobile no</label>
                      <Field type="text" name="Mobile-no2" class="form-control" id="Mobile-no2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Email</label>
                      <Field type="text" name="Email2" class="form-control" id="Email2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-3 link-1 anchor-tag">
                <label htmlFor="exampleInputPassword1"></label>
                <a href='#'>CNIC (Back side)</a>
              </div>
            </div>

            <div
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>4. Previous Education</Typography>
            </div>
            <h5 style={{ textIndent: "29%" }}>Verify Yes/No</h5>
            <div
              class="d-flex justify-content-between flex"
            >
              <div className="column formsInput formfield">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Degree Name</label>
                      <Field type="text" name="DegreeName" class="form-control" id="DegreeName" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Roll/Reg no</label>
                      <Field type="text" name="Roll-no" class="form-control" id="Roll-no" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">University/ Institute</label>
                      <Field type="text" name="Uni-name" class="form-control" id="Uni-name" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Passing Year/Month</label>
                      <Field type="text" name="Passing-year" class="form-control" id="Passing-year" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Marks Obtained</label>
                      <Field type="text" name="Marks-obt" class="form-control" id="Marks-obt" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Total Marks</label>
                      <Field type="text" name="Total-marks" class="form-control" id="Total-marks" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Grade Division</label>
                      <Field type="text" name="Grade" class="form-control" id="Grade" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <button type="button" className="btn btn-success" onClick={addDiv1}>
                    More Education
                  </button>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Degree Name</label>
                      <Field type="text" name="DegreeName2" class="form-control" id="DegreeName2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Roll/Reg no</label>
                      <Field type="text" name="Roll-no2" class="form-control" id="Roll-no2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">University/ Institute</label>
                      <Field type="text" name="Uni-name2" class="form-control" id="Uni-name2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Passing Year/Month</label>
                      <Field type="text" name="Passing-year2" class="form-control" id="Passing-year2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Marks Obtained</label>
                      <Field type="text" name="Marks-obt2" class="form-control" id="Marks-obt2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Total Marks</label>
                      <Field type="text" name="Total-marks2" class="form-control" id="Total-marks2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Grade Division</label>
                      <Field type="text" name="Grade2" class="form-control" id="Grade2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-3 link-1 anchor-tag">
                <label htmlFor="exampleInputPassword1"></label>
                <a href='#'>Certificate</a>
              </div>
            </div>

            <div
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>5. Prior Test Results</Typography>
            </div>
            <h5 style={{ textIndent: "29%" }}>Verify Yes/No</h5>
            <div
              class="d-flex justify-content-between flex"
            >
              <div className="column formsInput formfield">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Test Title</label>
                      <Field type="text" name="Test-title" class="form-control" id="Test-title" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Institute/ Organization</label>
                      <Field type="text" name="Institute" class="form-control" id="Institute" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Test Date</label>
                      <Field type="text" name="Test-date" class="form-control" id="Test-date" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Test Result</label>
                      <Field type="text" name="Result" class="form-control" id="Result" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Valid Upto</label>
                      <Field type="text" name="FirstName" class="form-control" id="Valid" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <button type="button" className="btn btn-success" onClick={addDiv1}>
                    More Test Results
                  </button>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Test Title</label>
                      <Field type="text" name="Test-title2" class="form-control" id="Test-title2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Institute/ Organization</label>
                      <Field type="text" name="Institute2" class="form-control" id="Institute2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Test Date</label>
                      <Field type="text" name="Test-date2" class="form-control" id="Test-date2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Test Result</label>
                      <Field type="text" name="Result2" class="form-control" id="Result2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Valid Upto</label>
                      <Field type="text" name="Valid2" class="form-control" id="Valid2" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 link-1 anchor-tag">
                <label htmlFor="exampleInputPassword1"></label>
                <a href='#'>Test Results</a>
              </div>
            </div>
            <div
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>6. Application Processing Fee</Typography>
            </div>
            <h5 style={{ textIndent: "29%" }}>Verify Yes/No</h5>
            <div
              class="d-flex justify-content-between flex"
            >
              <div className="column formsInput formfield">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Challan/ Voucher no</label>
                      <Field type="text" name="Challan" class="form-control" id="Challan" aria-describedby="emailHelp" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Amount</label>
                      <Field type="text" name="Amount" class="form-control" id="Amount" aria-describedby="emailHelp" />
                    </div>
                  </div>
                  <div class=" col-4" style={{ margin: "11px" }}>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "#c74e11" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="exampleCheckbox" style={{ backgroundColor: "black" }} />
                      <label class="form-check-label" for="exampleCheckbox">
                        No
                      </label>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-3 link-1 anchor-tag">
                <label htmlFor="exampleInputPassword1"></label>
                {/* <input type="file" className="form-control" id="exampleInputPassword1" /> */}
                <a href='#'>Payment Receipt</a>
              </div>
            </div>


            {/* <button type="submit" className="btn btn-primary">Submit</button> */}
          </Form>
        )}
      </Formik>
      <div className="btns-operations">
        <button type="button" className="btn btn-success btn-op">Submit</button>
      </div>
    </div>
  )
}

export default AddForm