import React, { createContext, useState } from 'react';

export const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
  const [color, setColor] = useState('#FFFFFF');

  const changeColor = (newColor) => {
    setColor(newColor);
  };

  const contextValue = {
    color,
    changeColor,
  };

  return (
    <ColorContext.Provider value={contextValue}>
      {children}
    </ColorContext.Provider>
  );
};