import React, { createContext, useState } from 'react';

import constants from '../config/constants';

const AuthContext = createContext(null);
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        accessToken: null,
        refreshToken: null,
        user: null,
        authenticated: null
    });

    const logout = async () => {
        await localStorage.deleteItemAsync(constants.authSessionKey);
        setAuthState({
            accessToken: null,
            refreshToken: null,
            user: null,
            authenticated: false
        });
    };

    const getAccessToken = () => {
        return authState.accessToken;
    };


    const getFullName = () => {
        try {
            const user = authState.user;
            return user.Title + " " + user.FirstName + " " + user.LastName;
        }
        catch {
            logout();
            return null;
        }
    };


    return (
        <Provider
            value={{
                authState,
                getAccessToken,
                setAuthState,
                getFullName,
                logout
            }}>
            {children}
        </Provider>
    );
};

export { AuthContext, AuthProvider };