import React, { useState } from 'react';
import { IoMdMenu, IoIosTimer } from 'react-icons/io';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import './navbar.css';
import Badge from '@mui/material/Badge';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link, useNavigate } from 'react-router-dom';

const NavBar = ({ handleMenuItemClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (menuItem, event) => {
    event.stopPropagation();
    handleMenuItemClick(menuItem);
    setSelectedItem(menuItem);

    if (menuItem === 'Admission Form') {
      setShowSubMenu('subMenu1');
    } else {
      setShowSubMenu(null);
    }

    setIsOpen(false); // Close the menu
  };

  // const handleSubMenuItemClick = (subMenuItem, event) => {
  //   event.stopPropagation();
  //   // Handle sub menu item click here
  //   console.log('Selected sub menu item:', subMenuItem);
  // };

  const handleIconClick = (event) => {
    event.stopPropagation();
  
    if (selectedItem === 'Admission Form') {
      setIsOpen(false); // Close the menu
    }  setShowMenu(!showMenu);
  };
  const handleLogout = () => {
    // Perform logout functionality here
    // For example, clear session, remove tokens, etc.
    console.log('Logged out!');
  };

  const handleSubMenuItemClick1 = (subMenuItem, event) => {
    event.stopPropagation();
    // Navigate to the desired component based on the submenu item
    if (subMenuItem === 'Sub Menu 1') {
      navigate('/MeritList'); // Use history to navigate to the component
    }
  };

  return (
    <div className="navbar">
      <div className="menu-icon" onClick={toggleMenu}>
        <IoMdMenu size={28} />
      </div>
      {isOpen && (
        <ul className="menu-list">
          <li onClick={(event) => handleClick('Admission Form', event)}>
            <Link to="/" style={{ color: 'black', textDecoration: 'none' }}>
              Admission Form
            </Link>
          </li>
          <li onClick={(event) => handleClick('Menu 2',event)}>Menu 2</li>
          <li onClick={(event) => handleClick('Menu 3',event)}>Menu 3</li>
          <li onClick={(event) => handleClick('Setting', event)}>
            <Link to="/Setting" style={{ color: 'black', textDecoration: 'none' }}>
             Setting
            </Link>
          </li>
        </ul>
      )}
      <div className="selected-menu" onClick={handleIconClick}>
        <div className="selected-item">{selectedItem}</div>
        {showSubMenu === 'subMenu1' && (
          <div className="submenu">
            <ul className="sub-menu">
              <li onClick={(event) => handleSubMenuItemClick1('Sub Menu 1', event)}>
                <Link to="/MeritList" style={{ color: 'white', textDecoration: 'none' }}>
                  Merit List
                </Link>
              </li>
              {/* <li onClick={() => handleSubMenuItemClick('Sub Menu 2')}>Sub Menu 2</li>
              <li onClick={() => handleSubMenuItemClick('Sub Menu 3')}>Sub Menu 3</li> */}
            </ul>
          </div> )}
        {showSubMenu === 'subMenu2' && (
          <div className="submenu">
            <ul className="sub-menu">
              {/* <li onClick={() => handleSubMenuItemClick('Sub Menu 4')}>Sub Menu 4</li>
              <li onClick={() => handleSubMenuItemClick('Sub Menu 5')}>Sub Menu 5</li>
              <li onClick={() => handleSubMenuItemClick('Sub Menu 6')}>Sub Menu 6</li> */}
            </ul>
          </div>
        )}
      </div>
      <div className="notification-badge">
        <Badge badgeContent={4} color="primary">
          <QuestionAnswerOutlinedIcon size={28} />
        </Badge>
        <Badge badgeContent={4} color="primary">
          <IoIosTimer size={28} />
        </Badge>
        <Badge badgeContent={4} color="primary">
          <EmailOutlinedIcon size={28} />
        </Badge>
      </div>
      <div className="log-out">
        AST ADMIN
        <AccountCircleOutlinedIcon className="logout-icon" onClick={handleIconClick} />
        {showMenu && (
          <div className="logout-menu">
            <span onClick={handleLogout}>Logout</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default NavBar;